var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-bg pt-4 pb-4"
  }, [[_c('advertisement-top-banner-text')], _c('div', {
    staticClass: "account-pages"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-6 text-right"
  }, [_c('div', {
    staticClass: "login-left-side pt-3"
  }, [_c('h2', {
    style: {
      color: '#0b4990'
    }
  }, [_vm._v(" One App for all your needs! ")]), _c('h3', {
    style: {
      color: '#0b4990'
    }
  }, [_vm._v(" Enjoy your Shopping with us! ")])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "card mt-4 rounded-lg"
  }, [_c('div', {
    staticClass: "card-header pt-2 pb-2 text-center bg-orange rounded-top"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('b-img', {
    attrs: {
      "src": "/img/login/logo_icon.png",
      "width": "55px",
      "height": "55px",
      "fluid": ""
    }
  })], 1), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('b-img', {
    attrs: {
      "src": "/img/login/e-পরিষেবা_text logo.png",
      "width": "150px",
      "height": "auto"
    }
  })], 1)])]), _c('div', {
    staticClass: "card-body p-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Email/Phone Number"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter Password"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Confirm Password"
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1), _c('div', {
    staticClass: "text-left"
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1",
      "value": "accepted"
    }
  }, [_vm._v(" I Accepted "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Terms & conditionn")])])], 1), _c('div', {
    staticClass: "form-group pt-4 pb-3 text-center"
  }, [_c('b-button', {
    staticClass: "primary-btn",
    attrs: {
      "size": "lg"
    }
  }, [_vm._v("Create An Account")])], 1), _c('div', {
    staticClass: "text-center pt-3"
  }, [_c('p', [_vm._v(" Already Have An Account? "), _c('router-link', {
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_vm._v("Login")])], 1)]), _c('div', {
    staticClass: "form-group text-center"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-primary",
      "size": ""
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "facebook",
      "aria-label": "Help"
    }
  }), _vm._v(" Facebook")], 1), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "outline-danger",
      "size": ""
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "google",
      "aria-label": "Help"
    }
  }), _vm._v(" Google")], 1)], 1)])])])])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }