<template>
  <div class="login-bg pt-4 pb-4">
  <template>
      <advertisement-top-banner-text />
    </template>
    <div class="account-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-right">
            <div class="login-left-side pt-3">
              <h2 v-bind:style="{ color: '#0b4990' }">
                One App for all your needs!
              </h2>
              <h3 v-bind:style="{ color: '#0b4990' }">
                Enjoy your Shopping with us!
              </h3>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mt-4 rounded-lg">
              <!-- Logo -->
              <div
                class="card-header pt-2 pb-2 text-center bg-orange rounded-top"
              >
                <div class="row">
                  <div class="col-lg-3">
                    <b-img
                      src="/img/login/logo_icon.png"
                      width="55px"
                      height="55px"
                      fluid
                    ></b-img>
                    <!-- <img src="/img/logo_icon.png"/> -->
                  </div>
                  <div class="col-lg-7">
                    <b-img
                      src="/img/login/e-পরিষেবা_text logo.png"
                      width="150px"
                      height="auto"
                    ></b-img>
                    <!-- <img src="/img/e-পরিষেবা_text logo.png"/> -->
                  </div>
                </div>
                <!-- <span class="text-white font-weight-bold h2">E parisheva</span> -->
              </div>
              <div class="card-body p-4">
                <div class="form-group">
                  <b-form-input
                    v-model="text"
                    placeholder="Email/Phone Number"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    v-model="text"
                    placeholder="Enter Password"
                  ></b-form-input>
                </div>
                <div class="form-group">
                  <b-form-input
                    v-model="text"
                    placeholder="Confirm Password"
                  ></b-form-input>
                </div>
                <div class="text-left">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    value="accepted"
                  >
                    I Accepted <a href="#">Terms & conditionn</a>
                  </b-form-checkbox>
                </div>

                <div class="form-group pt-4 pb-3 text-center">
                  <b-button class="primary-btn" size="lg"
                    >Create An Account</b-button
                  >
                </div>

                <div class="text-center pt-3">
                  <p>
                    Already Have An Account?
                    <router-link :to="{ name: 'login' }">Login</router-link>
                  </p>
                </div>
                <div class="form-group text-center">
                  <b-button class="mr-3" variant="outline-primary" size=""
                    ><b-icon icon="facebook" aria-label="Help"></b-icon>
                    Facebook</b-button
                  >
                  <b-button class="ml-3" variant="outline-danger" size=""
                    ><b-icon icon="google" aria-label="Help"></b-icon>
                    Google</b-button
                  >
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "",
    };
  },
};
</script>
<style scoped>
.login-bg {
  background-image: url("/img/login/login_bg_image.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
}
.bg-orange {
  background: #2a8750;
}
.primary-btn {
  background: #2a8750;
  color: #fff;
  border-color: #2a8750;
  padding: 0.5rem 5rem;
}
</style>
